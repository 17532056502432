import React from 'react';


function Resources() {
    return (
        <>
            <h2>Технические ресурсы</h2>
            <p>Использованные ресурсы при разработке и работе сайта</p>
            <p><a href='https://react.dev/' target='_blank'>React v18.3.1</a></p>
            <p><a href='https://reactrouter.com/' target='_blank'>React Router v6.26.2</a></p>
            <p><a href='https://www.npmjs.com/package/@discord-message-components/react' target='_blank'>Discord message components v0.2.1</a></p>
            <p><a href='https://discord.com/developers/docs/intro' target='_blank'>Discord http bot api</a></p>
            <p><a href='https://threejs.org/' target='_blank'>Three js v0.168.0</a></p>
            <p><a href='https://www.cloudflare.com/developer-platform/workers/' target='_blank'>CloudFlare workers</a></p>
            <p><a href='https://www.cloudflare.com/developer-platform/pages/' target='_blank'>CloudFlare pages</a></p>
            <p><a href='https://www.cloudflare.com/ddos/' target='_blank'>CloudFlare ddos</a></p>
        </>
    )
};

export default Resources;