import React from 'react';

function Project() {
    return (
        <>
            <h2 style={{ textAlign: 'center' }}>О проекте</h2>
            <h3 className='disclaimer'><b>САЙТ/СЕРВЕР НЕ ЯВЛЯЕТСЯ ОФИЦИАЛЬНЫМ ПРОДУКТОМ SES, НЕ ОДОБРЕН И НЕ СВЯЗАН С SYSTEM ERA SOFTWORKS</b></h3>
            <img className='image_cat' alt="ИЗВИНИТЕ, СПЕКТАКЛЯ НЕ БУДЕТ. МЫ ОБОССАЛИ РЕКВИЗИТ" src="./media/brandbirdcat.gif"/>
            <p><b>Ostap_India</b> - <b>разработал идею сервера.</b> Ещё примерно в 2021 году стартовал первый сезон в режиме анархии.</p>
            <p>Тогда на его канале не было красивого оформления, качественной картинки и другого, что мы видим сейчас на канале.</p>
            <p>За <b>все 6 сезонов</b>, игроки нашли столько сетевых и серверных багов, сколько разработчикам и не снилось.</p>
            <p>Также игроки используют мод на простой чат прямо в игре, который позволяет лучше понять, что хотел сказать игрок.</p>
            <p>Есть также выдающиеся игроки, которые построили значимый проект или другое.</p>
            <p>Сезоны начинаются в основном в начале значимых каникул и праздников, как Новый год.</p>
            <p>В межсезонье иногда чередуются: анархия, хардкор, эксперименты, съёмки и другое.</p>
            <p>Подробную информацию установку модов и заход на сервер, можете найти в <b>верхнем нав-баре👍</b></p>
        </>
    )
};

export default Project;