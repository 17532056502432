import React from 'react';
import SnowFalling from './snowFalling';

function SnowEffectToggle() {
  const isWinter = () => {
    const currentMonth = new Date().getMonth(); // Месяцы: 0 - январь, 1 - февраль, ..., 11 - декабрь
    const winter = currentMonth === 11 || currentMonth === 0 || currentMonth === 1; // Декабрь, Январь, Февраль
    return winter;
  };

  const showSnow = isWinter();

  return (
    <>
      {showSnow && <SnowFalling />}
    </>
  );
}

export default SnowEffectToggle;
