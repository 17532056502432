import React, { useEffect, useState } from 'react';
import {
	DiscordDefaultOptions,
	DiscordInteraction,
	DiscordMarkdown,
	DiscordMention,
	DiscordMessage,
	DiscordMessages,
	DiscordOptionsContext,
	DiscordReaction,
	DiscordReactions,
} from '@discord-message-components/react';
import '@discord-message-components/react/styles';

export default function Ds() {
	const [discordOptions, setDiscordOptions] = useState(null);
	const [userInfo] = useState({
		author: 'Guest',
		avatar: 'https://cdn.discordapp.com/embed/avatars/4.png',
		roleColor: '#00ff5f',
	});

	useEffect(() => {
		// Проверка кеша на наличие профиля бота
		const cachedBotProfile = localStorage.getItem('bot_profile');

		if (cachedBotProfile) {
			const parsedBotProfile = JSON.parse(cachedBotProfile);
			const options = {
				...DiscordDefaultOptions,
				profiles: {
					guest: userInfo,
					bot: {
						author: parsedBotProfile.username,
						avatar: `https://cdn.discordapp.com/avatars/${parsedBotProfile.id}/${parsedBotProfile.avatar}.gif?size=2048`,
						roleColor: '#ffdf00',
					},
				},
			};
			setDiscordOptions(options);
			console.log('Загрузка из кеша')
		} else {
			// Запрос к API для получения данных профиля бота
			fetch('https://worker.astrocreate.space/get_bot_profile')
				.then(response => response.json())
				.then(data => {
					if (data.status === 'success') {
						const { botProfile } = data;
						// Сохранение профиля бота в кеш
						localStorage.setItem('bot_profile', JSON.stringify(botProfile));

						// Формирование discordOptions с данными из профиля
						const options = {
							...DiscordDefaultOptions,
							profiles: {
								guest: userInfo,
								bot: {
									author: botProfile.username,
									avatar: `https://cdn.discordapp.com/avatars/${botProfile.id}/${botProfile.avatar}.gif?size=2048`,
									roleColor: '#ffdf00',
								},
							},
						};
						setDiscordOptions(options);
						console.log('Загрузка в кеш')
					}
				})
				.catch(error => {
					console.error('Ошибка при получении профиля бота:', error);
				});
		}
	}, [userInfo]);

	if (!discordOptions) {
		// Пока данные загружаются, можно отобразить что-то вроде загрузочного индикатора
		return (
			<img className='image_cat' alt="ИЗВИНИТЕ, СПЕКТАКЛЯ НЕ БУДЕТ. МЫ ОБОССАЛИ РЕКВИЗИТ" src="https://i.pinimg.com/736x/19/3d/69/193d69a53f12b72eef63a63d248adefc.jpg"/>
		);
	}

	const triggerMessage = '+ Astrocreate 2';

	return (
		<DiscordOptionsContext.Provider value={discordOptions}>
			<DiscordMessages>
				<DiscordMessage profile="guest">
					{triggerMessage}
					<div slot="reactions">
						<DiscordReactions>
							<DiscordReaction name=":astro_favicon:" image="./media/1280583934618501171.webp" count={1} active={false} />
						</DiscordReactions>
					</div>
				</DiscordMessage>
				<DiscordMessage bot={true} profile="bot">
					<DiscordMention highlight={true}>{userInfo.author}</DiscordMention> - Вам выдана роль игрока.
					<div slot="interactions">
						<DiscordInteraction profile="guest" command={false}>{triggerMessage}</DiscordInteraction>
					</div>
				</DiscordMessage>
			</DiscordMessages>
		</DiscordOptionsContext.Provider>
	);
}
