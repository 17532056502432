import React, { useState, useEffect } from 'react';

async function fetchTeamData() {
  try {
    const response = await fetch('./jsons/team.json');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Ошибка при получении команды:', error);
    return [];
  }
};

function Team() {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    async function loadTeam() {
      const teamData = await fetchTeamData();
      setTeam(teamData);
    };
    loadTeam();
  }, []);

  return (
    <>
      <h3>Наша команда:</h3>
      {team.length > 0 ? (
        team.map((member, index) => (
          <p key={index}>
            <b>{member.name}</b> - {member.short}
          </p>
        ))
      ) : (
        <p>Загрузка данных о команде...</p>
      )}
    </>
  );
};

export default Team;
