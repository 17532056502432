import React, { useEffect, useRef } from 'react';

// Константы для настроек снежинок
const NUM_FLAKES = 140;      // Количество снежинок
const MIN_RADIUS = 2;        // Минимальный радиус снежинки
const MAX_RADIUS = 4;        // Максимальный радиус снежинки
const MIN_SPEED_X = -0.5;    // Минимальная скорость по X
const MAX_SPEED_X = 0.5;     // Максимальная скорость по X
const MIN_SPEED_Y = 1;       // Минимальная скорость по Y
const MAX_SPEED_Y = 3;       // Максимальная скорость по Y

function SnowFalling() {
  const canvasRef = useRef(null);

  function initSnow() {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const flakes = [];

    // Задаем параметры для каждой снежинки
    function createFlakes() {
      for (let i = 0; i < NUM_FLAKES; i++) {
        flakes.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          speedX: Math.random() * (MAX_SPEED_X - MIN_SPEED_X) + MIN_SPEED_X,
          speedY: Math.random() * (MAX_SPEED_Y - MIN_SPEED_Y) + MIN_SPEED_Y,
          radius: Math.random() * (MAX_RADIUS - MIN_RADIUS) + MIN_RADIUS,
        });
      }
    }

    // Анимация движения снежинок
    function drawFlakes() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'white';
      ctx.beginPath();

      flakes.forEach((flake) => {
        ctx.moveTo(flake.x, flake.y);
        ctx.arc(flake.x, flake.y, flake.radius, 0, Math.PI * 2);
      });

      ctx.fill();
      moveFlakes();
    }

    // Логика перемещения снежинок
    function moveFlakes() {
      flakes.forEach((flake) => {
        flake.x += flake.speedX;
        flake.y += flake.speedY;

        // Возвращаем снежинку наверх экрана, если она упала за пределы
        if (flake.y > canvas.height) {
          flake.y = 0;
          flake.x = Math.random() * canvas.width;
        }

        // Если снежинка вышла за боковые границы, меняем направление
        if (flake.x > canvas.width || flake.x < 0) {
          flake.speedX *= -1;
        }
      });
    }

    // Функция для обновления кадра анимации
    function animateSnow() {
      drawFlakes();
      requestAnimationFrame(animateSnow);
    }

    // Инициализация
    createFlakes();
    animateSnow();
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    initSnow();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <canvas 
      ref={canvasRef} 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -69,  // Высокий z-index, чтобы быть на переднем плане
        pointerEvents: 'none'  // Это позволяет взаимодействовать с элементами под canvas
      }} 
    />
  );
}

export default SnowFalling;
