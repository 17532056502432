import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber'; 
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import * as THREE from 'three';
import './Animation.css'; 

// Определите данные планет
const planets = [
  { name: 'Glacio', radius: 1, position: [-5, 0, 0], color: 0x00cdff, emissive: 0x00cdff },
  { name: 'Desolo', radius: 0.5, position: [5, 0, 0], color: 0x9bb870, emissive: 0x9bb870 },
  { name: 'Vesania', radius: 1, position: [0, 0, -5], color: 0xfe00fe, emissive: 0xfe00fe },
  { name: 'Calidor', radius: 1, position: [0, 0, 5], color: 0xe76600, emissive: 0xe76600 },
  { name: 'Atrox', radius: 1, position: [0, -5, 0], color: 0x7fcd00, emissive: 0x7fcd00 },
  { name: 'Novus', radius: 0.5, position: [0, 5, 0], color: 0xdd9aff, emissive: 0xdd9aff },
  { name: 'Satellite', radius: 0.3, position: [0, -5, 5], color: 0x9500c7, emissive: 0x9500c7 },
  { name: 'Sylva', radius: 1, position: [0, 0, 0], color: 0x12a162, emissive: 0x12a162 },
  { name: 'Sun room', radius: 0.5, position: [-10, 0, 0], color: 0xffffff, emissive: 0xffffff },
];

// Компонент планеты
function Planet({ position, radius, color, emissive }) {
  const meshRef = useRef(null);

  return (
    <mesh ref={meshRef} position={position}>
      <sphereGeometry args={[radius, 32, 32]} />
      <meshStandardMaterial color={color} emissive={emissive} emissiveIntensity={1} />
    </mesh>
  );
}

// Компонент звезды
function Star() {
  const meshRef = useRef();

  useEffect(() => {
    // Устанавливаем случайное положение звезды в пределах радиуса 25-55 от центра сцены
    const distance = Math.random() * (75 - 25) + 25;
    const angle1 = Math.random() * Math.PI * 2;
    const angle2 = Math.random() * Math.PI;

    // Конвертируем сферические координаты в декартовы
    const x = distance * Math.sin(angle2) * Math.cos(angle1);
    const y = distance * Math.sin(angle2) * Math.sin(angle1);
    const z = distance * Math.cos(angle2);

    // Устанавливаем позицию
    meshRef.current.position.set(x, y, z);

    // Устанавливаем случайное вращение по осям x, y и z
    meshRef.current.rotation.set(
      Math.random() * 2 * Math.PI,
      Math.random() * 2 * Math.PI,
      Math.random() * 2 * Math.PI
    );
  }, []);

  return (
    <mesh ref={meshRef}>
      <coneGeometry args={[0.3, 0.2, 3]} />
      <meshStandardMaterial color={0xffffff} emissive={0xffffff} emissiveIntensity={0.8} />
    </mesh>
  );
}

// Компонент управления
function Controls() {
  const { camera, gl } = useThree(); 

  useEffect(() => {
    if (camera && gl) {
      const controls = new OrbitControls(camera, gl.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.enableZoom = true;

      return () => {
        controls.dispose();
      };
    }
  }, [camera, gl]);

  return null;
}

function DirectionalArrows() {
  const arrowsRef = useRef(null);

  useEffect(() => {
    if (arrowsRef.current) {
      const size = 3;
      const arrowHelperParams = [
        { dir: new THREE.Vector3(0, 0, -1), color: 0x0000ff, label: 'Север' }, // Север
        { dir: new THREE.Vector3(0, 0, 1), color: 0xff0000, label: 'Юг' }, // Юг
      ];

      arrowHelperParams.forEach(({ dir, color, label }) => {

        const arrow = new THREE.ArrowHelper(dir, new THREE.Vector3(0, 0, 0), size, color);
        arrowsRef.current.add(arrow);

        if (arrow.position && arrow.direction) {
          // Позиция для текста
          const position = arrow.position.clone().add(arrow.direction.clone().multiplyScalar(size)); 

          // Создание материала для спрайта с шрифтом
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          context.font = '24px Arial';  // Устанавливаем шрифт
          context.fillStyle = '#ffffff';  // Цвет текста
          context.fillText(label, 0, 30);  // Добавляем текст на канвас

          const texture = new THREE.CanvasTexture(canvas);  // Текстура для спрайта
          const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
          const sprite = new THREE.Sprite(spriteMaterial);

          sprite.position.copy(position);
          sprite.scale.set(1, 1, 1); // Устанавливаем размер спрайта
          sprite.material.opacity = 1;  // Убираем прозрачность
          sprite.name = label;  // Присваиваем метку
          arrowsRef.current.add(sprite);
        } else {
          console.warn('Ошибка: не удалось получить позицию стрелки или направление');
        }
      });
    }
  }, []);

  return <group ref={arrowsRef} />;
}

// Компонент сцены
function Scene() {
  const numStars = 300;

  return (
    <>
      {planets.map((planet) => (
        <Planet
          key={planet.name}
          position={planet.position}
          radius={planet.radius}
          color={planet.color}
          emissive={planet.emissive}
        />
      ))}
      {[...Array(numStars)].map((_, index) => (
        <Star key={index} radius={Math.random() * 0.5 + 0.15} color={0xffffff} />
      ))}
      <Controls />
      <DirectionalArrows />
      <EffectComposer>
        <Bloom intensity={0.8} width={500} height={500} kernelSize={2} />
        <Bloom intensity={0.1} width={500} height={500} kernelSize={4} />
      </EffectComposer>
    </>
  );
}

function App() {
  const [showAnimation, setShowAnimation] = useState(true);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [isGrabbing, setIsGrabbing] = useState(false);

  useEffect(() => {
    document.body.style.backgroundImage = null;
    document.body.style.backgroundColor = 'black';
    const timer = setTimeout(() => {
      setLoadingComplete(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (loadingComplete) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [loadingComplete]);

  // Handlers for mouse events
  const handleMouseDown = () => setIsGrabbing(true);
  const handleMouseUp = () => setIsGrabbing(false);

  return (
    <>
      {showAnimation && (
        <div className="fullscreen-animation">
          <img src="./favicons/favicon.gif" alt="Rocket" className="rocket" />
        </div>
      )}
      <Canvas
        camera={{ position: [-10, 0, 10], fov: 75 }}
        style={{
          height: '100vh',
          width: '100%',
          backgroundColor: 'black',
          cursor: isGrabbing ? 'grabbing' : 'grab',
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <Scene />
      </Canvas>
    </>
  );
}

export default App;
