import React from 'react';
import ReactDom from 'react-dom/client';
import '../../images.css'
import DsMsgsPlay from '../SubComponents/DsMsgsPlay';

function Play() {
    return (
        <>
            <h2 style={{ textAlign: 'center' }}>Как играть</h2>
            <div className="line"></div>
            <p>
            1. Для начала, зайдем в{' '}
            <a
                style={{ textDecoration: 'none', color: 'white' }}
                href="https://discord.gg/a5BwGUBFkd"
                target="_blank"
                rel="noopener noreferrer"
            >
                спец. канал в дс
            </a>{' '}
            и прочитаем внимательно его описание.
            </p>
            <p>2. Напишем свой ник, пример:</p>
            <DsMsgsPlay/>
            <p>Если вы правильно написали ник, то спец.бот поставит на сообщение с ником реакцию.</p>
            <p>А, если вы неправильно написали ник, то бот напишет вам в лс об этом.</p>
            <p>
            3. После того, как вам выдадут роль <b>Игрок</b>, вам будет доступна категория{' '}
            <b>Astrocreate season 6 ANARCHY</b>. В ней вы найдете канал{' '}
            <a
                style={{ textDecoration: 'none', color: 'white' }}
                href="https://discord.gg/t4UgNwfrze"
                target="_blank"
                rel="noopener noreferrer"
            >
                ❗новости-сервера❗
            </a>
            , и там в{' '}
            <a
                style={{ textDecoration: 'none', color: 'white' }}
                href="https://discord.com/channels/931931132583346276/1125516381127987200/1130593641040191488"
                target="_blank"
                rel="noopener noreferrer"
            >
                закрепе
            </a>{' '}
            вы найдёте данные для входа и другую инфу.
            </p>
            <p>4. Заходим в <b>Astroneer</b> и находим такую вкладку:</p>
            <img
            loading="lazy"
            src="./media/Play/search.png"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            alt="Поиск сервера"
            />
            <p>В url сервера пишем айпи.</p>
            <p>В name сервера пишем всё что угодно.</p>
            <img
            loading="lazy"
            src="./media/Play/ip_paste.png"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            alt="Вставка IP"
            />
            <p>После нажатия <b>ДОБАВИТЬ СЕРВЕР</b>, вводим пароль.</p>
            <img
            loading="lazy"
            src="./media/Play/pass_paste_fix.png"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            alt="Ввод пароля"
            />
            <p>И присоединяемся.</p>
            <img
            loading="lazy"
            src="./media/Play/join_fix.png"
            style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            alt="Присоединение"
            />
            <h3>
            P.s. Мы специально не даем прямой доступ к серверу, т.к. слоты сервера ограничены и
            отследить кто именно зашел на сервер будет невозможно.
            </h3>
        </>
    )
};

export default Play;