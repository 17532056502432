import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFoundPage.css'; // Подключаем файл стилей

function NotFoundPage() {
  const navigate = useNavigate();

  function goToHome() {
    navigate('/');
  }

  return (
    <>
      <div className="center">
        <h1>Страница не найдена</h1>
        <p>Извините, запрашиваемая страница или другой контент не существует.</p>
        <button
          className="goBackButton"
          onClick={goToHome}
        >
          На главную
        </button>
      </div>
    </>
  );
}

export default NotFoundPage;
